import React from 'react'

function VediosIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 10.2909L19.4752 8.14876C19.7837 7.95857 20.171 7.95019 20.4875 8.12686C20.8039 8.30353 21 8.63759 21 9.00002V15.9968C21 16.3593 20.8039 16.6933 20.4875 16.87C20.171 17.0466 19.7838 17.0383 19.4753 16.8481L16 14.7059'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x={3}
        y={6}
        width={13}
        height={13}
        rx={3}
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default VediosIcon
