import * as React from 'react'
import { SVGProps } from 'react'
const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22' width={20} height={22} fill='none' {...props}>
    <path
      fill='url(#a)'
      d='m4.455 20.755.232-.713-.232.713Zm-3.21-3.21.713-.232-.713.232Zm17.51 0-.713-.232.713.232Zm-3.21 3.21-.232-.713.232.713Zm0-13.51-.232.713.232-.713Zm3.21 3.21-.713.232.713-.232Zm-14.3-3.21.232.713-.232-.713Zm-3.21 3.21.713.232-.713-.232ZM10.75 12a.75.75 0 0 0-1.5 0h1.5Zm-1.5 4a.75.75 0 0 0 1.5 0h-1.5ZM8 7.75h4v-1.5H8v1.5Zm4 12.5H8v1.5h4v-1.5Zm-4 0c-1.92 0-2.702-.01-3.313-.208l-.464 1.427c.895.29 1.974.281 3.777.281v-1.5ZM.25 14c0 1.803-.01 2.882.281 3.777l1.427-.464C1.759 16.702 1.75 15.92 1.75 14H.25Zm4.437 6.042a4.25 4.25 0 0 1-2.729-2.729l-1.427.464a5.75 5.75 0 0 0 3.692 3.692l.464-1.427ZM18.25 14c0 1.92-.01 2.702-.208 3.313l1.427.464c.29-.895.281-1.974.281-3.777h-1.5ZM12 21.75c1.803 0 2.882.01 3.777-.281l-.464-1.427c-.611.199-1.393.208-3.313.208v1.5Zm6.042-4.437a4.25 4.25 0 0 1-2.729 2.729l.464 1.427a5.75 5.75 0 0 0 3.692-3.692l-1.427-.464ZM19.75 14c0-1.803.01-2.882-.281-3.777l-1.427.464c.199.611.208 1.393.208 3.313h1.5Zm-4.437-6.042a4.25 4.25 0 0 1 2.729 2.729l1.427-.464a5.75 5.75 0 0 0-3.692-3.692l-.464 1.427ZM1.75 14c0-1.92.01-2.702.208-3.313l-1.427-.464C.241 11.118.25 12.197.25 14h1.5Zm2.473-7.469a5.75 5.75 0 0 0-3.692 3.692l1.427.464a4.25 4.25 0 0 1 2.729-2.729l-.464-1.427ZM14.25 6v1h1.5V6h-1.5Zm-8.5 1V6h-1.5v1h1.5ZM10 1.75A4.25 4.25 0 0 1 14.25 6h1.5A5.75 5.75 0 0 0 10 .25v1.5Zm0-1.5A5.75 5.75 0 0 0 4.25 6h1.5A4.25 4.25 0 0 1 10 1.75V.25ZM9.25 12v4h1.5v-4h-1.5ZM8 6.25c-1.411 0-2.372-.003-3.125.124l.25 1.48C5.722 7.752 6.528 7.75 8 7.75v-1.5Zm-3.125.124c-.227.038-.442.09-.652.157l.464 1.427c.13-.042.272-.077.438-.105l-.25-1.479ZM4.25 7v.114h1.5V7h-1.5Zm7.75.75c1.472 0 2.278.003 2.875.103l.25-1.479c-.753-.127-1.713-.124-3.125-.124v1.5Zm2.875.103c.166.028.308.063.438.105l.464-1.427a4.532 4.532 0 0 0-.652-.157l-.25 1.48ZM14.25 7v.114h1.5V7h-1.5Z'
    />
    <defs>
      <linearGradient id='a' x1={10} x2={10} y1={1} y2={21} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#FF624A' />
        <stop offset={1} stopColor='#D11C00' />
      </linearGradient>
    </defs>
  </svg>
)
export default LockIcon
