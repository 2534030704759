import { configureStore } from '@reduxjs/toolkit'
import htmlReducer from './slices/htmlSlice'
import quizReducer from './quizSlice/quizSlice'
import siteReducer from './siteSlice/siteSlice'

export const store = configureStore({
  reducer: {
    html: htmlReducer,
    quiz: quizReducer,
    site: siteReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
