import React, { useState } from 'react'
import GetContainer from '../../../components/get-container'
import PaginatedItems from '../../../components/pagination'
import { useTranslation } from 'react-i18next'

function Payments() {
  const [pageNumber, setPageNumber] = useState(1)
  const [search, setSearch] = useState('')
  const { i18n , t } = useTranslation()
  return (
    <GetContainer
      url='dashboard/user/payments'
      params={{
        perPage: 10,
        page: pageNumber,
        search: search
      }}
    >
      {({ data }) => (
        <div className='account-tab'>
          <div className='account-search'>
            <div className='header-search'>
              <div className='header-search__input'>
                <svg width={20} height={20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <g clipPath='url(#clip0_2522_8521)'>
                    <path
                      d='M19.6334 17.871L15.7624 13.9984C18.6588 10.1278 17.8691 4.64195 13.9984 1.74551C10.1278 -1.15092 4.64195 -0.361157 1.74551 3.50949C-1.15092 7.38013 -0.361157 12.866 3.50949 15.7624C6.61871 18.0891 10.8892 18.0891 13.9984 15.7624L17.871 19.635C18.3577 20.1216 19.1467 20.1216 19.6333 19.635C20.12 19.1483 20.12 18.3593 19.6333 17.8727L19.6334 17.871ZM8.78623 15.015C5.34618 15.015 2.55751 12.2263 2.55751 8.78623C2.55751 5.34618 5.34618 2.55751 8.78623 2.55751C12.2263 2.55751 15.015 5.34618 15.015 8.78623C15.0113 12.2247 12.2248 15.0113 8.78623 15.015Z'
                      fill='currentColor'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_2522_8521'>
                      <rect width={20} height={20} fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <input
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  style={{
                    paddingLeft: 30
                  }}
                  type='text'
                  placeholder={t('Search')}
                />
              </div>
            </div>
          </div>
          <div className='account-payment'>
            {data?.data?.map((payment: any) => (
              <div className='account-payment__item'>
                <div className='account-payment__status'>{t('Passed')}</div>
                <div className='account-payment__text'>{JSON.parse(payment?.message)?.[i18n.language]}</div>
                <div className='account-payment__wrap'>
                  <div className='account-payment__date'>12:30, 12.02.2022</div>
                  <div className='account-payment__price'>1 200 000.00 {t('UZS')}</div>
                </div>
              </div>
            ))}
          </div>
          {data && <PaginatedItems total={data?.meta?.total} itemsPerPage={10} setPageNumber={setPageNumber} />}
        </div>
      )}
    </GetContainer>
  )
}

export default Payments
