import React from 'react'

function ArticleIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.414 6.414L15.586 3.586C15.211 3.211 14.702 3 14.172 3H7C5.895 3 5 3.895 5 5V19C5 20.105 5.895 21 7 21H17C18.105 21 19 20.105 19 19V7.828C19 7.298 18.789 6.789 18.414 6.414V6.414Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19 8H15C14.448 8 14 7.552 14 7V3'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 11.1211H11.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M16 14H11.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M16 16.8789H11.25'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.125 11C8.056 11 8 11.056 8 11.125C8 11.194 8.056 11.25 8.125 11.25C8.194 11.25 8.25 11.194 8.25 11.125C8.25 11.056 8.194 11 8.125 11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.125 13.875C8.056 13.875 8 13.931 8 14C8 14.069 8.056 14.125 8.125 14.125C8.194 14.125 8.25 14.069 8.25 14C8.25 13.931 8.194 13.875 8.125 13.875'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.125 16.75C8.056 16.75 8 16.806 8 16.875C8 16.944 8.056 17 8.125 17C8.194 17 8.25 16.944 8.25 16.875C8.25 16.806 8.194 16.75 8.125 16.75'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ArticleIcon
