import React from 'react'
import './App.scss'
import './css/main.scss'
import './configs/i18n'

import { Navigate, Route, Routes } from 'react-router-dom'
import { router_data } from './data/router_data'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { store } from './store'

import ImageModal from './components/image-modal'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Toaster } from 'react-hot-toast'
import 'react-loading-skeleton/dist/skeleton.css'
import AuthProvider from './providers/auth-provider'

const queryClient = new QueryClient()

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes>
            <Route path='/' element={<Navigate to='/library' replace />} />
            {router_data.map(route => (
              <Route {...route} key={route.path} />
            ))}
          </Routes>
          <ImageModal />
          <Toaster
            toastOptions={{
              success: {
                style: {
                  display: 'inline-flex',
                  padding: '1.875rem 3.375rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                  borderRadius: '1.25rem',
                  background: 'linear-gradient(180deg, #84BC3F 0%, #4FA446 100%)',
                  color: '#ffff',
                  fontFamily: 'Gilroy',
                  fontSize: '1.5rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '116%'
                }
              },
              error: {
                style: {
                  display: 'inline-flex',
                  padding: '1.875rem 3.375rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '1rem',
                  borderRadius: '1.25rem',
                  background: 'linear-gradient(180deg, #FF624A 0%, #D11C00 100%)',
                  color: '#ffff',
                  fontFamily: 'Gilroy',
                  fontSize: '1.5rem',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '116%'
                }
              }
            }}
          />
        </AuthProvider>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
