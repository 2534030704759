import React from 'react'

function LogoText() {
  return (
    <svg width={143} height={27} viewBox='0 0 143 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.6627 18.5858C14.9536 19.4374 13.9512 20.1002 12.6556 20.5743C11.36 21.0396 9.92425 21.2723 8.34847 21.2723C6.6939 21.2723 5.24068 20.9123 3.98882 20.1924C2.7457 19.4637 1.78273 18.4102 1.09989 17.0318C0.425805 15.6535 0.0800087 14.0337 0.0625 12.1725V10.8687C0.0625 8.95482 0.382033 7.29992 1.0211 5.904C1.66892 4.4993 2.59688 3.42822 3.80497 2.69076C5.02183 1.94451 6.4444 1.57139 8.07271 1.57139C10.3401 1.57139 12.1128 2.11571 13.391 3.20435C14.6691 4.28421 15.4263 5.8601 15.6627 7.93203H11.8283C11.6532 6.83461 11.2637 6.0313 10.6596 5.5221C10.0643 5.0129 9.24141 4.7583 8.19089 4.7583C6.85148 4.7583 5.8316 5.26311 5.13126 6.27273C4.43091 7.28236 4.07636 8.78363 4.0676 10.7765V12.0013C4.0676 14.0117 4.44842 15.5306 5.21004 16.5577C5.97167 17.5849 7.08785 18.0985 8.55857 18.0985C10.0381 18.0985 11.093 17.7825 11.7233 17.1503V13.8449H8.13837V10.9346H15.6627V18.5858Z'
        fill='currentColor'
      />
      <path
        d='M22.6224 21.0089H18.8143V6.75999H22.6224V21.0089ZM18.591 3.07266C18.591 2.502 18.7792 2.03231 19.1557 1.66357C19.5409 1.29484 20.0618 1.11048 20.7183 1.11048C21.3662 1.11048 21.8827 1.29484 22.2678 1.66357C22.653 2.03231 22.8456 2.502 22.8456 3.07266C22.8456 3.6521 22.6487 4.12618 22.2547 4.49491C21.8695 4.86365 21.3574 5.04801 20.7183 5.04801C20.0793 5.04801 19.5628 4.86365 19.1688 4.49491C18.7836 4.12618 18.591 3.6521 18.591 3.07266Z'
        fill='currentColor'
      />
      <path
        d='M38.5509 14.0161C38.5509 16.211 38.0519 17.9712 37.0539 19.2969C36.0647 20.6138 34.7253 21.2723 33.0357 21.2723C31.6 21.2723 30.44 20.7718 29.5558 19.771V26.4872H25.7608V6.75999H29.2801L29.4114 8.1559C30.3306 7.04971 31.5299 6.49661 33.0094 6.49661C34.7603 6.49661 36.1216 7.14628 37.0933 8.44562C38.065 9.74497 38.5509 11.536 38.5509 13.8186V14.0161ZM34.7559 13.7396C34.7559 12.4139 34.5195 11.3911 34.0468 10.6712C33.5828 9.95128 32.9044 9.59133 32.0114 9.59133C30.8208 9.59133 30.0023 10.0479 29.5558 10.9609V16.7948C30.0198 17.7342 30.8471 18.2039 32.0377 18.2039C33.8498 18.2039 34.7559 16.7158 34.7559 13.7396Z'
        fill='currentColor'
      />
      <path
        d='M53.7046 14.0161C53.7046 16.211 53.2056 17.9712 52.2076 19.2969C51.2184 20.6138 49.879 21.2723 48.1894 21.2723C46.7537 21.2723 45.5938 20.7718 44.7096 19.771V26.4872H40.9146V6.75999H44.4338L44.5651 8.1559C45.4843 7.04971 46.6837 6.49661 48.1632 6.49661C49.914 6.49661 51.2753 7.14628 52.247 8.44562C53.2188 9.74497 53.7046 11.536 53.7046 13.8186V14.0161ZM49.9096 13.7396C49.9096 12.4139 49.6733 11.3911 49.2005 10.6712C48.7366 9.95128 48.0581 9.59133 47.1652 9.59133C45.9746 9.59133 45.156 10.0479 44.7096 10.9609V16.7948C45.1735 17.7342 46.0008 18.2039 47.1914 18.2039C49.0036 18.2039 49.9096 16.7158 49.9096 13.7396Z'
        fill='currentColor'
      />
      <path
        d='M55.4774 13.7527C55.4774 12.3393 55.7488 11.0794 56.2915 9.97323C56.8343 8.86703 57.6134 8.01104 58.6289 7.40527C59.6532 6.79949 60.8394 6.49661 62.1876 6.49661C64.1048 6.49661 65.6674 7.08482 66.8755 8.26126C68.0924 9.43769 68.7708 11.0355 68.9109 13.0548L68.9372 14.0293C68.9372 16.2153 68.3287 17.9712 67.1119 19.2969C65.895 20.6138 64.2624 21.2723 62.2138 21.2723C60.1653 21.2723 58.5283 20.6138 57.3027 19.2969C56.0858 17.98 55.4774 16.189 55.4774 13.9239V13.7527ZM59.2724 14.0293C59.2724 15.3813 59.5263 16.4173 60.034 17.1372C60.5418 17.8483 61.2684 18.2039 62.2138 18.2039C63.133 18.2039 63.8509 17.8527 64.3674 17.1503C64.8839 16.4392 65.1422 15.3067 65.1422 13.7527C65.1422 12.4271 64.8839 11.3999 64.3674 10.6712C63.8509 9.9425 63.1243 9.57816 62.1876 9.57816C61.2596 9.57816 60.5418 9.9425 60.034 10.6712C59.5263 11.3911 59.2724 12.5105 59.2724 14.0293Z'
        fill='currentColor'
      />
      <path
        d='M76.4352 15.2935L75.0696 16.6631V21.0089H71.2746V0.78125H75.0696V11.9881L75.8049 11.0399L79.4424 6.75999H83.999L78.8646 12.6992L84.4454 21.0089H80.0858L76.4352 15.2935Z'
        fill='currentColor'
      />
      <path
        d='M93.8739 21.0089C93.6988 20.6665 93.5718 20.2407 93.493 19.7315C92.5738 20.7587 91.3789 21.2723 89.9082 21.2723C88.5162 21.2723 87.3606 20.8684 86.4414 20.0607C85.531 19.253 85.0758 18.2346 85.0758 17.0055C85.0758 15.4954 85.6317 14.3366 86.7435 13.5289C87.864 12.7212 89.4792 12.3129 91.589 12.3041H93.3355V11.4877C93.3355 10.8292 93.1648 10.3025 92.8233 9.90738C92.4907 9.51231 91.961 9.31478 91.2344 9.31478C90.5954 9.31478 90.092 9.46842 89.7243 9.77569C89.3654 10.083 89.1859 10.5044 89.1859 11.0399H85.3909C85.3909 10.2147 85.6448 9.45086 86.1525 8.74851C86.6603 8.04616 87.3782 7.49745 88.3061 7.10238C89.2341 6.69853 90.2758 6.49661 91.4314 6.49661C93.1823 6.49661 94.5698 6.93996 95.5941 7.82668C96.6271 8.70461 97.1436 9.9425 97.1436 11.5403V17.7166C97.1524 19.0686 97.3406 20.0914 97.7083 20.785V21.0089H93.8739ZM90.7354 18.3619C91.2957 18.3619 91.8122 18.239 92.285 17.9932C92.7577 17.7386 93.1079 17.4006 93.3355 16.9792V14.5297H91.9173C90.0176 14.5297 89.0065 15.1882 88.8839 16.5051L88.8708 16.7289C88.8708 17.203 89.0371 17.5937 89.3698 17.901C89.7024 18.2083 90.1577 18.3619 90.7354 18.3619Z'
        fill='currentColor'
      />
      <path
        d='M103.617 6.75999L103.736 8.35344C104.742 7.11555 106.104 6.49661 107.82 6.49661C109.649 6.49661 110.905 7.2209 111.588 8.6695C112.586 7.2209 114.009 6.49661 115.856 6.49661C117.397 6.49661 118.544 6.94874 119.296 7.85302C120.049 8.74851 120.426 10.1005 120.426 11.9091V21.0089H116.618V11.9222C116.618 11.1145 116.46 10.5263 116.145 10.1576C115.83 9.78008 115.274 9.59133 114.477 9.59133C113.339 9.59133 112.551 10.1356 112.114 11.2243L112.127 21.0089H108.332V11.9354C108.332 11.1102 108.17 10.5132 107.846 10.1444C107.522 9.77569 106.97 9.59133 106.191 9.59133C105.114 9.59133 104.335 10.0391 103.854 10.9346V21.0089H100.059V6.75999H103.617Z'
        fill='currentColor'
      />
      <path
        d='M136.144 14.0161C136.144 16.211 135.645 17.9712 134.647 19.2969C133.658 20.6138 132.318 21.2723 130.629 21.2723C129.193 21.2723 128.033 20.7718 127.149 19.771V26.4872H123.354V6.75999H126.873L127.005 8.1559C127.924 7.04971 129.123 6.49661 130.603 6.49661C132.354 6.49661 133.715 7.14628 134.687 8.44562C135.658 9.74497 136.144 11.536 136.144 13.8186V14.0161ZM132.349 13.7396C132.349 12.4139 132.113 11.3911 131.64 10.6712C131.176 9.95128 130.498 9.59133 129.605 9.59133C128.414 9.59133 127.596 10.0479 127.149 10.9609V16.7948C127.613 17.7342 128.44 18.2039 129.631 18.2039C131.443 18.2039 132.349 16.7158 132.349 13.7396Z'
        fill='currentColor'
      />
      <path
        d='M138.705 19.1389C138.705 18.5331 138.906 18.0415 139.309 17.6639C139.72 17.2864 140.232 17.0977 140.845 17.0977C141.467 17.0977 141.979 17.2864 142.382 17.6639C142.793 18.0415 142.999 18.5331 142.999 19.1389C142.999 19.7359 142.797 20.2231 142.395 20.6006C141.992 20.9694 141.476 21.1537 140.845 21.1537C140.224 21.1537 139.712 20.9694 139.309 20.6006C138.906 20.2231 138.705 19.7359 138.705 19.1389Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default LogoText
