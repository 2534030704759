import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Article from '../../img/icons/Article'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { request } from '../../helpers/request'

const SAVE_ARTICLE = async (id: string | number) => {
  const response = await request({
    url: `dashboard/user/articles/${id}/read`,
    method: 'POST'
  })

  return response.data
}

interface IProps {
  article: any
}

function ArticleCard(props: IProps) {
  const [isRead, setIsRead] = useState(props.article?.is_read)
  const { i18n, t } = useTranslation()
  const { mutate, isLoading } = useMutation(SAVE_ARTICLE, {
    onSuccess: () => {
      setIsRead(!isRead)
    }
  })
  return (
    <div className='study-themes__item'>
      <Link to={'/article/' + props.article?.slug} className='study-themes__wrap'>
        <Article />
        <span>{props.article?.name?.[i18n.language]}</span>
      </Link>
      <label htmlFor={'theme1' + props?.article?.slug} className='study-themes__check'>
        <input
          checked={isRead}
          type='checkbox'
          disabled={isLoading}
          id={'theme1' + props?.article?.slug}
          onChange={() => mutate(props?.article?.id)}
          className='checkbox'
        />
        <span>{t('Mark as read')}</span>
      </label>
    </div>
  )
}

export default ArticleCard
