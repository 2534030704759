import React from 'react'

function MessagesIcon() {
  return (
    <svg width={25} height={24} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.4997 4.99693V15.0011C21.4997 16.1061 20.6039 17.0019 19.4989 17.0019H7.49385L3.49219 21.0036V4.99693C3.49219 3.8919 4.38799 2.99609 5.49302 2.99609H19.4989C20.6039 2.99609 21.4997 3.8919 21.4997 4.99693Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.4955 8.00021H8.49219'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.49219 12.0002H12.4939'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default MessagesIcon
