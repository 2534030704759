import React from 'react'

function NewsIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.99469 21.0036H5.99344C4.33589 21.0036 2.99219 19.6599 2.99219 18.0023V5.99734C2.99219 4.3398 4.33589 2.99609 5.99344 2.99609H17.9984C19.656 2.99609 20.9997 4.3398 20.9997 5.99734V8.99859'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7231 20.7135C14.5356 20.9011 14.2811 21.0066 14.0158 21.0066H12V18.9908C12 18.7255 12.1055 18.471 12.2931 18.2835L18.2196 12.352C18.5425 12.0291 18.9804 11.8477 19.4371 11.8477C19.8938 11.8477 20.3317 12.0291 20.6546 12.352V12.352C20.9778 12.6748 21.1594 13.1128 21.1594 13.5695C21.1594 14.0263 20.9778 14.4643 20.6546 14.787L14.7231 20.7135Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.0078 14.5625L18.4388 16.9935'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.1656 15.2695L21.066 16.1699V16.1699C21.54 16.6448 21.54 17.4137 21.066 17.8886L19.9766 18.9791'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24219 11.0002H13.9942'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24219 7.12521H15.995'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1174 11.1253C6.18646 11.1253 6.24245 11.0693 6.24245 11.0002C6.24245 10.9311 6.18646 10.8752 6.1174 10.8752C6.04833 10.8752 5.99234 10.9311 5.99234 11.0002C5.99234 11.0693 6.04833 11.1253 6.1174 11.1253'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1174 7.25026C6.18646 7.25026 6.24245 7.19427 6.24245 7.12521C6.24245 7.05614 6.18646 7.00016 6.1174 7.00016C6.04833 7.00016 5.99234 7.05614 5.99234 7.12521C5.99234 7.19427 6.04833 7.25026 6.1174 7.25026'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24219 14.8791H10.9929'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1174 15.0042C6.18646 15.0042 6.24245 14.9482 6.24245 14.8791C6.24245 14.8101 6.18646 14.7541 6.1174 14.7541C6.04833 14.7541 5.99234 14.8101 5.99234 14.8791C5.99234 14.9482 6.04833 15.0042 6.1174 15.0042'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default NewsIcon
