import React from 'react'

function MoonIcon() {
  return (
    <svg width={18} height={18} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7766 11.25C12.9016 11.85 10.7266 11.475 9.22656 9.975C7.35156 8.1 7.20156 5.1 8.70156 3C5.62656 3.3 3.22656 5.85 3.22656 9C3.22656 12.3 5.92656 15 9.22656 15C11.7016 15 13.8766 13.425 14.7766 11.25V11.25Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default MoonIcon
