import React from 'react'

function StudyIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.0234 17.0039V19.2533C15.0234 19.7646 15.3123 20.2321 15.7697 20.4607L16.2686 20.7102C17.0428 21.0973 17.9541 21.0973 18.7282 20.7102L19.2272 20.4607C19.6845 20.2321 19.9734 19.7646 19.9734 19.2533V17.0039'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4057 16.1663L16.4668 17.7487C17.1149 18.0837 17.8852 18.0837 18.5333 17.7487L21.5945 16.1663C21.8436 16.0375 22.0001 15.7805 22.0001 15.5C22.0001 15.2195 21.8436 14.9625 21.5945 14.8337L18.5333 13.2513C17.8852 12.9162 17.1149 12.9162 16.4668 13.2513L13.4057 14.8337C13.1565 14.9625 13 15.2195 13 15.5C13 15.7805 13.1565 16.0375 13.4057 16.1663Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22 15.5117V17.2487'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20 10V6C20 4.34315 18.6569 3 17 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7 8H16' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 12H11' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 16H9' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default StudyIcon
