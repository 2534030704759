import React from 'react'

function Folder() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21 11.2712V9C21 7.89543 20.1046 7 19 7H12.57C12.239 7 11.9294 6.8362 11.7432 6.56252L10.2974 4.43752C10.1112 4.16382 9.8016 4 9.47056 4H5C3.89543 4 3 4.89543 3 6V18.4169C3 19.2912 3.70877 20 4.58308 20V20C5.38973 20 6.06739 19.3935 6.15647 18.5918L6.80232 12.7791C6.91486 11.7663 7.77099 11 8.79009 11H19.9998C20.5686 11 21.1106 11.2423 21.49 11.6662C21.8694 12.09 22.0504 12.6555 21.9875 13.2209L21.432 18.2209C21.3194 19.2337 20.4633 20 19.4442 20H4.58308'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Folder
