import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setPhoto } from '../../store/slices/htmlSlice'
import { Tooltip } from 'react-tooltip'
import TooltipHtml from '../tooltip/TooltipHtml'
import parse from 'html-react-parser'
import '../../content.scss'

interface IProps {
  html: string
  isQuiz?: boolean
  openMarker?: boolean
}

function WithTooltip(props: IProps) {
  const { fontSize, showMarker, showAddInfo } = useSelector((state: RootState) => state.html)
  const [isOpen, setIsOpen] = useState(false)

  const newHtml = useMemo(() => {
    var ptRegex = /(\d+(\.\d+)?)pt/g
    var pxRegex = /(\d+(\.\d+)?)px/g

    // Function to convert points to pixels
    function ptToRem(match: any, p1: any) {
      var ptSize = parseFloat(p1)
      var pxSize = (ptSize * 1.33333 * fontSize) / 256 // Approximate conversion
      return pxSize + 'rem'
    }
    function pxToRem(match: any, p1: any) {
      var ptSize = parseFloat(p1)
      var pxSize = (ptSize * fontSize) / 256 // Approximate conversion
      return pxSize + 'rem'
    }
    return props?.html
      ?.replace(ptRegex, ptToRem)
      ?.replace(pxRegex, pxToRem)
      ?.replaceAll('color: black;', '')
      ?.replaceAll('color: windowtext;', '')
      ?.replaceAll('color: rgb(0, 0, 0);', '')
      ?.replaceAll('background: white;', '')
      .replace(/<table/g, '<div id="tableWrapper"><table')
      .replace(/<\/table>/g, '</table></div>')
  }, [props?.html, fontSize])
  const dispatch = useDispatch()

  useEffect(() => {
    const el = document.getElementsByTagName('u')
    setTimeout(() => {
      //@ts-ignore
      for (const item of el) {
        if (props.isQuiz) {
          item.style.display = 'inline'
          item.style.border = 'none'
          item.style.textDecoration = 'none'
          if (props.openMarker) {
            item.style.display = 'inline'
            item.style.textDecoration = 'none'
            item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
          }

          if (props.isQuiz && props.openMarker) {
            item.style.textDecoration = 'none'
            item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
          }
        } else {
          if (!showAddInfo && !showMarker) {
            item.style.display = 'none'
          }

          if (showAddInfo) {
            item.style.display = 'inline'
            item.style.border = 'none'
            item.style.textDecoration = 'none'
          }
          if (showAddInfo && showMarker) {
            item.style.textDecoration = 'none'
            item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
          }
          if (showMarker) {
            item.style.textDecoration = 'none'
            item.style.borderBottom = '2px solid rgba(249, 194, 6, 1)'
          }
        }
        item.style.width = 'auto'
      }
    })
  }, [dispatch, showAddInfo, showMarker, props.isQuiz, props.openMarker])

  const openImage = (url: string) => {
    dispatch(
      setPhoto({
        type: 'image',
        url: url
      })
    )
  }

  useEffect(() => {
    if (!!newHtml) {
      const childs = document.getElementById('tinymce')
      const aTags = document?.getElementsByTagName('a')
      if (aTags?.length) {
        //@ts-ignore
        for (const item of aTags) {
          const href = item?.href?.split('/')
          const isNote = href?.some((e: string) => e === 'article_note_text')
          const isPhoto = href?.some((e: string) => e === 'article_note_photos')
          if (isNote) {
            item.setAttribute('data-some-relevant-attr', '/dashboard' + item?.href?.split('dashboard')[1])
            item.setAttribute('data-tooltip-id', 'my-tooltip')
            item.removeAttribute('href')
          } else if (isPhoto) {
            item?.setAttribute('photo-url', '/dashboard' + item?.href?.split('dashboard')[1])
            item?.addEventListener('click', () => openImage(item?.getAttribute('photo-url')))
            item.style.color = 'red'
            item.removeAttribute('href')
            item.style.cursor = 'pointer'
          }
        }
      }
      const spans = childs?.getElementsByTagName('span')

      //@ts-ignore
      if (spans?.length) {
        //@ts-ignore
        for (const element of spans) {
          //@ts-ignore
          const elementStyle = element?.style
          if (!elementStyle.getPropertyValue('font-size')) {
            element.id = 'editable'
            elementStyle['font-size'] = `${fontSize / 16 + 'rem'}`
          }
          if (element.getAttribute('id') === 'editable') {
            elementStyle['font-size'] = `${fontSize / 16 + 'rem'}`
          }
        }
      }
      //   var tablesWrapper = document.getElementById('tinymce')

      //   // Check if the div exists
      //   if (tablesWrapper) {
      //     // Get all the tables inside the div
      //     var tables = tablesWrapper.querySelectorAll('table')

      //     // Create a new div to wrap the tables
      //     var divWrapper = document.createElement('div')
      //     divWrapper.id = 'tableWrapper'

      //     // Loop through the tables and move each table into the new div
      //     tables.forEach(function (table) {
      //       divWrapper.appendChild(table)
      //     })

      //     // Append the new div containing the tables to the original div
      //     tablesWrapper.appendChild(divWrapper)
      //   }
    }
  }, [newHtml, openImage, fontSize])

  return (
    <>
      <Tooltip
        id='my-tooltip'
        clickable
        offset={0}
        afterHide={() => setIsOpen(false)}
        afterShow={() => setTimeout(() => setIsOpen(true), 500)}
        className={!isOpen ? 'fade-out' : 'fade-in'}
        style={{
          display: isOpen ? 'block' : 'none',
          zIndex: 100
        }}
        render={({ content, activeAnchor }) => {
          return <TooltipHtml isOpen={isOpen} url={activeAnchor?.getAttribute('data-some-relevant-attr') as string} />
        }}
      />
      <div
        className='dangerous mce-content-body'
        id='tinymce'
        // style={{
        //   fontSize: fontSize
        // }}
      >
        {parse(`${newHtml}`)}
      </div>
    </>
  )
}

export default WithTooltip
