import React, { SVGProps } from 'react'

function LabIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M19.9993 7.99818V4.99693C19.9993 3.8919 19.1035 2.99609 17.9984 2.99609H4.99302C3.88799 2.99609 2.99219 3.8919 2.99219 4.99693V19.0028C2.99219 20.1078 3.88799 21.0036 4.99302 21.0036H9.9951'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24219 15.0041H10.9929'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24219 11.0002H11.9933'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.24219 7.12521H15.995'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.8534 11.0002H15.3516'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.1033 11V14.7836L21.6974 18.9453C22.0816 19.5616 22.1017 20.3376 21.7499 20.9729C21.3982 21.6082 20.7299 22.003 20.0037 22.0046H15.2067C14.4793 22.0056 13.8086 21.6117 13.4552 20.9759C13.1017 20.3401 13.1212 19.5626 13.506 18.9453L16.1001 14.7836V11'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.2343 15.0041H15.9609'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1174 15.1292C6.18646 15.1292 6.24245 15.0732 6.24245 15.0041C6.24245 14.9351 6.18646 14.8791 6.1174 14.8791C6.04833 14.8791 5.99234 14.9351 5.99234 15.0041C5.99234 15.0732 6.04833 15.1292 6.1174 15.1292'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1174 11.1253C6.18646 11.1253 6.24245 11.0693 6.24245 11.0002C6.24245 10.9311 6.18646 10.8752 6.1174 10.8752C6.04833 10.8752 5.99234 10.9311 5.99234 11.0002C5.99234 11.0693 6.04833 11.1253 6.1174 11.1253'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.1174 7.25026C6.18646 7.25026 6.24245 7.19427 6.24245 7.12521C6.24245 7.05614 6.18646 7.00016 6.1174 7.00016C6.04833 7.00016 5.99234 7.05614 5.99234 7.12521C5.99234 7.19427 6.04833 7.25026 6.1174 7.25026'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LabIcon
