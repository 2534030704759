import React, { SVGProps } from 'react'

function ShareIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M12 4L10 2L8 4' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 12V2' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M8 17V20C8 20.552 8.448 21 9 21H19C19.552 21 20 20.552 20 20V11C20 10.448 19.552 10 19 10H16'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 7H15C15.552 7 16 7.448 16 8V16C16 16.552 15.552 17 15 17H5C4.448 17 4 16.552 4 16V8C4 7.448 4.448 7 5 7H7'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ShareIcon
