import { useQuery } from 'react-query'
import { request } from '../../helpers/request'
import { ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface IProps {
  url: string
  enabled?: boolean
  params?: object
  children: (props: any) => JSX.Element
  onSuccess?: (data: any) => void
  onError?: (data: any) => void
}

function GetContainer(props: IProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const { data, isLoading, error, isError, refetch } = useQuery(
    [props.url, ...(props?.params ? Object.values(props.params) : [])],
    async () => {
      const response: any = await request({
        url: props.url,
        params: props.params,
        method: 'GET'
      })
      return response.data
    },
    {
      enabled: !!props.url,
      onSuccess: (data: any) => props.onSuccess && props.onSuccess(data),
      onError(err: any) {
        if (err?.response?.data?.message === 'Phone verification') {
          if (location.pathname === '/send-code') {
            navigate('/send-code')
          }
        } else if (err?.response?.data?.message === 'Device limit reached') {
        } else if (err?.response?.data?.message === 'Unauthorized') {
          if (location.pathname === '/sign-in') {
            navigate('/sign-in')
          }
        }
      }
    }
  )
  return props.children({ data, isLoading, error, isError, refetch })
}

export default GetContainer
