import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type IFeedback = {
  article_slug?: string
  chapter_id?: number
  block_id?: number
  question_id?: number
  type: string
} | null

export interface htmlState {
  fontSize: number
  chapters: any[]
  showMarker: boolean
  showAddInfo: boolean
  photo: {
    type: 'image' | 'video' | 'quiz'
    url: string
  } | null
  feedback: IFeedback
  lang: 'uz' | 'ru' | 'en'
}

const initialState: htmlState = {
  fontSize: 16,
  chapters: [],
  showMarker: false,
  showAddInfo: false,
  photo: null,
  feedback: null,
  lang: 'uz'
}

export const htmlSlice = createSlice({
  name: 'html',
  initialState,
  reducers: {
    increment: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (state.fontSize < 30) {
        state.fontSize += 1
      }
    },
    decrement: state => {
      if (state.fontSize > 10) {
        state.fontSize -= 1
      }
    },
    setFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload
    },
    setChapters: (state, action: PayloadAction<any>) => {
      state.chapters = action.payload?.map((el: any) => {
        return {
          ...el,
          isOpen: false
        }
      })
    },
    toggleChapter: (state, action: PayloadAction<number>) => {
      state.chapters = state.chapters?.map((chapter, index) =>
        index === action.payload ? { ...chapter, isOpen: !chapter?.isOpen } : chapter
      )
    },
    openChapter: (state, action: PayloadAction<number>) => {
      state.chapters = state.chapters?.map(chapter =>
        chapter?.id === action.payload ? { ...chapter, isOpen: true } : chapter
      )
    },
    toggleAllChapter: (state, action: PayloadAction<boolean>) => {
      state.chapters = state.chapters?.map((chapter, index) => {
        return { ...chapter, isOpen: action.payload }
      })
    },
    toggleShowMarker: state => {
      state.showMarker = !state.showMarker
    },
    toggleAddInfo: state => {
      state.showAddInfo = !state.showAddInfo
    },
    setPhoto: (
      state,
      action: PayloadAction<{
        type: 'image' | 'video' | 'quiz'
        url: string
      }>
    ) => {
      state.photo = {
        type: action.payload.type,
        url: action.payload.url
      }
    },
    setClosePhoto: state => {
      state.photo = null
    },
    resetFontSize: state => {
      state.fontSize = Number(getComputedStyle(document.documentElement).fontSize?.split('px')?.[0])
    },
    setFeedback: (state, action: PayloadAction<IFeedback>) => {
      state.feedback = action.payload
    },
    setLang: (state, action: PayloadAction<'uz' | 'ru' | 'en'>) => {
      state.lang = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  setFontSize,
  setChapters,
  toggleChapter,
  toggleAllChapter,
  toggleShowMarker,
  toggleAddInfo,
  openChapter,
  setPhoto,
  setClosePhoto,
  resetFontSize,
  setLang
} = htmlSlice.actions

export default htmlSlice.reducer
