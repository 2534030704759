import React from 'react'

function WalletIcon() {
  return (
    <svg width={25} height={24} viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.5 11.5V9C20.5 7.89543 19.6046 7 18.5 7H5C4.17157 7 3.5 6.32843 3.5 5.5V5.5C3.5 4.67157 4.17157 4 5 4H17.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 11.5H20.5C21.0523 11.5 21.5 11.9477 21.5 12.5V15.5C21.5 16.0523 21.0523 16.5 20.5 16.5H19C17.6193 16.5 16.5 15.3807 16.5 14V14C16.5 12.6193 17.6193 11.5 19 11.5V11.5Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.5 16.5V19C20.5 20.1046 19.6046 21 18.5 21H6C4.61929 21 3.5 19.8807 3.5 18.5V5.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7.5 10V18' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default WalletIcon
