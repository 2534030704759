import React from 'react'

function SettingsIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.50499 10.6982V10.6982C3.72854 9.82268 3.59946 8.54849 4.18456 7.535L4.22495 7.465C4.81007 6.45153 5.97805 5.92618 7.12454 6.16077H7.12465C7.59463 6.25694 8.08355 6.1776 8.49899 5.93774V5.93774C8.91443 5.69789 9.22759 5.31414 9.37929 4.85906V4.85906C9.74937 3.74885 10.7883 3 11.9586 3H12.0394C13.2096 3 14.2486 3.74885 14.6187 4.85906V4.85906C14.7704 5.31414 15.0836 5.69788 15.499 5.93773V5.93773C15.9144 6.1776 16.4034 6.25695 16.8733 6.16078H16.8735C18.0199 5.92621 19.1879 6.45156 19.773 7.465L19.8134 7.535C20.3985 8.54849 20.2695 9.82271 19.493 10.6983V10.6983C19.1747 11.0572 18.999 11.5203 18.999 12V12C18.999 12.4797 19.1748 12.9428 19.493 13.3018V13.3018C20.2695 14.1773 20.3986 15.4516 19.8134 16.465L19.773 16.5351C19.1879 17.5485 18.0199 18.0739 16.8735 17.8393L16.8732 17.8392C16.4032 17.7431 15.9144 17.8224 15.499 18.0622V18.0622C15.0836 18.302 14.7704 18.6858 14.6187 19.1409V19.1409C14.2486 20.2511 13.2096 21 12.0393 21H11.9587C10.7884 21 9.74938 20.2511 9.37928 19.1408V19.1408C9.2276 18.6858 8.91443 18.302 8.49899 18.0622V18.0622C8.0836 17.8224 7.59475 17.743 7.12484 17.8392L7.12454 17.8392C5.97806 18.0738 4.8101 17.5485 4.22499 16.535L4.18459 16.465C3.59946 15.4515 3.72851 14.1773 4.50497 13.3017V13.3017C4.82324 12.9428 4.99899 12.4797 4.99899 12V12C4.99901 11.5203 4.82326 11.0572 4.50499 10.6982Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx={12}
        cy={12}
        r={3}
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SettingsIcon
