import React from 'react'

function LibraryIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19 6H20C21.1046 6 22 6.89543 22 8V19C22 20.1046 21.1046 21 20 21H4C2.89543 21 2 20.1046 2 19V8C2 6.89543 2.89543 6 4 6H5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 20.0008V5.83984'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5.83882V5.83882C13.2922 4.38506 15.0536 3.43078 16.9771 3.14225L17.8517 3.01107C18.1396 2.96789 18.432 3.0523 18.6526 3.24227C18.8732 3.43225 19 3.70891 19 4.00003V16.1386C19 16.6336 18.6379 17.0541 18.1483 17.1276L16.9771 17.3033C15.0536 17.5918 13.2922 18.5461 12 19.9998V19.9998'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 5.8388V5.8388C10.7078 4.38505 8.94644 3.43078 7.02291 3.14225L6.14834 3.01107C5.86045 2.96789 5.56803 3.0523 5.34744 3.24227C5.12685 3.43224 5 3.7089 5 4.00001V16.1386C5 16.6336 5.36214 17.0541 5.85166 17.1276L7.02291 17.3032C8.94645 17.5918 10.7078 18.546 12 19.9998V19.9998'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default LibraryIcon
