import React, { useState } from 'react'
import GetContainer from '../../../components/get-container'
import i18n from '../../../configs/i18n'
import { MEDIA_URL, request } from '../../../helpers/request'
import { useTranslation } from 'react-i18next'

import DeviceCard from '../../../components/device-card'
import toast from 'react-hot-toast'

const SET_TARIFF = async (id: number) => {
  const response = await request({
    url: 'dashboard/user/tariffs/' + id,
    method: 'GET'
  })

  return response?.data
}

function Tariffs() {
  const [termId, setTermId] = useState(1)
  const { t } = useTranslation()

  return (
    <div className='account-tab'>
      <div className='account-rate rate'>
        <div className='rate-card'>
          <div className='rate__subtitle'>{t('Our tariff plan')}</div>
          <div className='rate-table'>
            <div className='rate-table__row rate-table__head'>
              <div>{t('Purchased')}</div>
              <div>{t('Tariff')}</div>
              <div>{t('It ends')}</div>
              <div>{t('Additional Information')}</div>
            </div>
            <div className='rate-table__row'>
              <div>2023-06-05 16:32:23 </div>
              <div>{t('+1 device')}</div>
              <div>2023-06-05 16:32:23 </div>
              <div>{t('Connected')}</div>
            </div>
          </div>
        </div>
        <div className='rate-card'>
          <div className='rate__subtitle'>{t('Devices')}</div>
          <div className='rate-devices'>
            <GetContainer url='dashboard/user/devices'>
              {({ data, refetch }) =>
                data?.data?.map((item: any) => <DeviceCard key={item?.id} item={item} refetch={refetch} />)
              }
            </GetContainer>
          </div>
        </div>
        <div className='rate-head'>
          <h2 className='rate__title section-title'>{t('Tariffs')}</h2>
          <GetContainer url='dashboard/user/terms'>
            {({ data }) => (
              <div className='rate-head__list'>
                {data?.data?.map((item: any) => (
                  <button onClick={() => setTermId(item?.id)} className={termId === item.id ? 'current' : ''}>
                    {item?.name?.[i18n.language]}
                  </button>
                ))}
              </div>
            )}
          </GetContainer>
        </div>
        <GetContainer url='dashboard/user/tariffs'>
          {({ data }) => (
            <div className='rate-wrap'>
              {data?.data
                ?.filter((item: any) => item.term_id?.id === termId)
                ?.map((el: any) => (
                  <div className='rate-item'>
                    <div
                      className='rate-item__card'
                      style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                      }}
                    >
                      <div className='rate-item__head'>
                        <div className='rate-item__wrap'>
                          <div className='rate-item__name'>{el?.name?.[i18n.language]}</div>
                          <div className='rate-item__text'>{t('What is included?')}</div>
                        </div>
                        <div className='rate-item__ico'>
                          <img src={MEDIA_URL + el?.photo} alt='ico' />
                        </div>
                      </div>
                      <ul className='rate-item__list'>
                        {el?.advantages?.map((val: any) => (
                          <li className={val.status ? 'active' : ''}>
                            <div className='rate-item__check' />
                            <p>{val?.name?.[i18n.language]}</p>
                          </li>
                        ))}
                      </ul>
                      <div className='rate-item__price'>{el.price} UZS</div>
                      <button
                        onClick={() =>
                          SET_TARIFF(el?.id)
                            .then(res => toast.success(res.message))
                            .catch(e => toast.error(e.message))
                        }
                        className='rate-item__btn btn'
                      >
                        {t('Purchase')}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </GetContainer>
      </div>
    </div>
  )
}

export default Tariffs
