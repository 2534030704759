import React from 'react'

function TariffsIcon() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.93837 5.97266C3.87637 7.06266 3.10537 8.43266 2.73438 9.96066'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.28906 20.4437C9.88006 20.5717 10.4931 20.6427 11.1231 20.6427C12.0151 20.6427 12.8761 20.5067 13.6861 20.2557'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.73438 14.043C3.10537 15.571 3.87637 16.941 4.93837 18.031'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.4073 14.4648C18.9883 15.8768 18.2143 17.1338 17.1953 18.1428'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.1953 5.85938C18.2143 6.86838 18.9873 8.12537 19.4073 9.53737'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.28906 3.55628C9.88006 3.42828 10.4931 3.35728 11.1231 3.35728C12.0151 3.35728 12.8761 3.49328 13.6861 3.74428'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.5334 10.2322C22.5097 11.2085 22.5097 12.7915 21.5334 13.7678C20.5571 14.7441 18.9742 14.7441 17.9979 13.7678C17.0215 12.7915 17.0215 11.2085 17.9979 10.2322C18.9742 9.25592 20.5571 9.25592 21.5334 10.2322'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.83026 17.5721C9.80657 18.5484 9.80657 20.1313 8.83026 21.1076C7.85395 22.0839 6.27104 22.0839 5.29473 21.1076C4.31842 20.1313 4.31842 18.5484 5.29473 17.5721C6.27104 16.5958 7.85395 16.5958 8.83026 17.5721'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.83026 2.8963C9.80657 3.87261 9.80657 5.45552 8.83026 6.43183C7.85395 7.40814 6.27104 7.40814 5.29473 6.43183C4.31842 5.45551 4.31842 3.87261 5.29473 2.8963C6.27104 1.91998 7.85395 1.91998 8.83026 2.8963'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default TariffsIcon
