import React, { useMemo, useState } from 'react'
import Navbar from '../navbar'
import LogoImage from '../../img/icons/LogoImage'
import LogoText from '../../img/icons/LogoText'
import SunIcon from '../../img/icons/SunIcon'
import MoonIcon from '../../img/icons/MoonIcon'
import CloseIcon from '../../img/icons/CloseIcon'
import LibrarySideMenu from '../library_side_menu'
import LibraryFooter from '../library_footer'
import LibraryPlan from '../library_plan'
import LibraryMobileHeader from '../library_mobile_header'
import LibraryMobilePlan from '../library_mobile_plan'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import { setTheme } from '../../store/siteSlice/siteSlice'
import { useTranslation } from 'react-i18next'
export const GET_THEME = () => {
  const theme = window.localStorage.getItem('theme')
  return theme
}

function LibraryLayout(props: any) {
  const {t} = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [openMobilePlan, setOpenMobilePlan] = useState(false)
  const { isDark } = useSelector((state: RootState) => state.site)
  const dispatch = useDispatch()

  return (
    <body className={isDark ? 'dark' : ''}>
      <div className='app'>
        {/* SIDE */}
        <aside className={`side ${isCollapsed ? 'side-mini' : ''}`}>
          <a href='/' className='side-logo'>
            <LogoImage />
            <div className='side-logo__text'>
              <LogoText />
            </div>
          </a>
          <div className='side-content'>
            <div className='side-wrap side-wrap__hidden'>
              <LibrarySideMenu />
              <div className='side-mode'>
                <button
                  className={isDark ? 'light' : 'current light'}
                  onClick={() => {
                    dispatch(setTheme(!isDark))
                  }}
                >
                  <SunIcon />
                  <span>Light</span>
                </button>
                <button
                  className={!isDark ? 'dark' : 'current dark'}
                  onClick={() => {
                    dispatch(setTheme(!isDark))
                  }}
                >
                  <MoonIcon />
                  <span>{t('Dark')}</span>
                </button>
              </div>
            </div>
            <LibraryPlan isHide={isCollapsed} />
          </div>
          <button
            className={`side__close btn  ${isCollapsed ? 'closed' : ''}`}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <CloseIcon />
          </button>
        </aside>
        {/* MAIN */}
        <main className='main'>
          {/* HEADER */}
          <Navbar openMobileMenu={() => {}} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
          {/* MOBILE LIBRARY*/}
          <LibraryMobileHeader openPlan={() => setOpenMobilePlan(true)} />
          {/* CONTENT*/}
          <div
            className='content'
            style={{
              width: '100%',
              height: '90dvh',
              overflowY: 'scroll'
            }}
            id='nestedRelativeContainerElement'
          >
            {/* LIBRARY*/}
            {props.children}
          </div>
        </main>
      </div>
      {/* LIBRARY MOBILE PLAN */}
      <LibraryMobilePlan isVisible={openMobilePlan} hide={() => setOpenMobilePlan(false)} />
      {/* LIBRARY GALLERY */}

      {/* FOOTER, footer-library for this page */}
      <LibraryFooter />
    </body>
  )
}

export default LibraryLayout
