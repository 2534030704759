import React, { Dispatch, SetStateAction } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useMutation } from 'react-query'
import { FORGOT_SEND_CODE } from '../mutations'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'
interface IFormInput {
  code: string
}
interface IProps {
  setCode: Dispatch<SetStateAction<string>>
  setToken: Dispatch<SetStateAction<string>>
  token: string
}
function CheckCode(props: IProps) {
  const {t} = useTranslation(); 
  const formSchema = Yup.object().shape({
    code: Yup.string().required((t('Code is required'))).min(4, t('Code is incorrect ')).max(4, t('Code is correct'))
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>({
    mode: 'onTouched',
    //@ts-ignore
    resolver: yupResolver(formSchema)
  })
  const { mutate } = useMutation(FORGOT_SEND_CODE, {
    onSuccess: data => {
      props.setToken(data?.data?.token)
      props.setCode(data?.data?.code)
    }
  })
  const onSubmit: SubmitHandler<IFormInput> = data => mutate({ ...data, token: props.token })
  return (
    <div className='login-content'>
      <h1 className='login__title section-title'>{t('Verification')}</h1>
      <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='login-form__item'>
          <div className='login-form__text'>{t('Enter the SMS code*')}</div>
          <div className='login-form__input'>
            <input type='text' placeholder='_ _ _ _' {...register('code')} />
          </div>
          <ErrorMessage errors={errors} name='code' />
        </div>

        <div className='login-form__enter'>
          {t('Profile available')} ? <Link to='/sign-in'>{t('Kirish')}</Link>
        </div>

        <button type='submit' className='login-form__btn btn'>
          {t('Continue')}
        </button>
      </form>
    </div>
  )
}

export default CheckCode
